import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, RedirectComponent } from '@digital-factory/ds-common-ui';
import { ROUTE_PATH } from './app-routing';
import { ErrorComponent } from './error/error.component';
import { FrameComponent } from './frame/frame.component';
import { InstitutionManagementComponent } from './institution-management/institution-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { InstitutionAddEditViewComponent } from './institution-management/institution-add-edit-view/institution-add-edit-view.component';
import { UserAddEditViewComponent } from './user-management/user-add-edit-view/user-add-edit-view.component';

export const ROUTES: Routes = [
  { path: ROUTE_PATH.error, component: ErrorComponent },
  { path: `${ROUTE_PATH.error}/:id`, component: ErrorComponent },
  {
    path: '',
    component: FrameComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: RedirectComponent,
        pathMatch: 'full',
        data: { defaultRoute: ROUTE_PATH.institutions },
      },
      {
        path: `${ROUTE_PATH.institutions}/:id`,
        component: InstitutionAddEditViewComponent,
        canDeactivate: [(component: InstitutionAddEditViewComponent) => component.canDeactivate()],
      },
      {
        path: ROUTE_PATH.institutions,
        component: InstitutionManagementComponent,
      },
      {
        path: `${ROUTE_PATH.institutions}/:tenantId/${ROUTE_PATH.users}/:userId`,
        component: UserAddEditViewComponent,
        canDeactivate: [(component: UserAddEditViewComponent) => component.canDeactivate()],
      },
      {
        path: `${ROUTE_PATH.institutions}/:tenantId/${ROUTE_PATH.users}`,
        component: UserManagementComponent,
      },
    ],
  },
  { path: '**', redirectTo: `${ROUTE_PATH.error}/404` },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
