@if (form) {
  <div class="institution-add-edit-view">
    <div class="heading">
      <h1 id="UserAddEditView-Title">{{ title }}</h1>
    </div>
    <button mat-button color="primary" [routerLink]="usersPath" id="UserAddEditView-BackButton">
      <span class="icon-arrow-left2"></span
      ><span id="UserAddEditView-TitleBackToUserList">&nbsp;Back to {{ tenant.tenant_name }} User List</span>
    </button>
    <form [formGroup]="form">
      <ng-container *ngTemplateOutlet="main_"></ng-container>
      @if (mode !== 'view') {
        <ng-container *ngTemplateOutlet="buttons_"></ng-container>
      }
    </form>
  </div>
}

<ng-template #section_ let-sectionTitle>
  <div class="section-title margin-top">
    {{ sectionTitle }}
  </div>
  <div class="divider top">
    <mat-divider></mat-divider>
  </div>
</ng-template>

<ng-template #main_>
  <ng-container *ngTemplateOutlet="section_; context: { $implicit: fullName }"> </ng-container>
  <div class="section">
    <div>
      <label class="required" for="UserAddEditView-EmailText" id="UserAddEditView-EmailLabel">Email</label>
      <input class="field-input" [formControl]="controls.user_name" id="UserAddEditView-EmailText" maxlength="241" />
      @if (hasError(controls.user_name, 'required')) {
        <div class="error-text" id="UserAddEditView-EmailRequiredFieldLabel">
          {{ error.required }}
        </div>
      } @else {
        @if (hasError(controls.user_name, 'pattern')) {
          <div class="error-text" id="UserAddEditView-InvalidEmailFormatLabel">Invalid email format.</div>
        }
      }
    </div>
    <div>
      <label class="required" for="UserAddEditView-LastNameText" id="UserAddEditView-LastNameLabel">Last Name</label>
      <input class="field-input" [formControl]="controls.last_name" id="UserAddEditView-LastNameText" maxlength="40" />
      @if (hasError(controls.last_name, 'required')) {
        <div class="error-text" id="UserAddEditView-LastNameRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
      @if (hasError(controls.last_name, 'pattern')) {
        <div class="error-text" id="UserAddEditView-InvalidLastNameLabel">Invalid last name.</div>
      }
    </div>

    <div>
      <label class="required" for="UserAddEditView-FirstNameText" id="UserAddEditView-FirstNameLabel">First Name</label>
      <input
        class="field-input"
        [formControl]="controls.first_name"
        id="UserAddEditView-FirstNameText"
        maxlength="40"
      />
      @if (hasError(controls.first_name, 'required')) {
        <div class="error-text" id="UserAddEditView-FirstNameRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
      @if (hasError(controls.first_name, 'pattern')) {
        <div class="error-text" id="UserAddEditView-InvalidFirstNameLabel">Invalid first name.</div>
      }
    </div>
    <div>
      <label for="UserAddEditView-DepartmentText" id="UserAddEditView-DepartmentLabel">Department</label>
      <input
        class="field-input"
        [formControl]="controls.department"
        id="UserAddEditView-DepartmentText"
        maxlength="60"
      />
      @if (hasError(controls.department, 'pattern')) {
        <div class="error-text" id="UserAddEditView-InvalidDepartmentNameLabel">Invalid department name.</div>
      }
    </div>
    <div>
      <label class="required" for="UserAddEditView-RoleSelect" id="UserAddEditView-RoleLabel">Role</label>
      <select class="field-input" [formControl]="controls.role" id="UserAddEditView-RoleSelect">
        @for (key of roleDisplayKeys; track key) {
          <option [value]="key">{{ roleDisplay[key] }}</option>
        }
      </select>
      @if (hasError(controls.role, 'required')) {
        <div class="error-text" id="UserAddEditView-RoleRequiredFieldLabel">
          {{ error.required }}
        </div>
      }
    </div>
    <div>
      <label for="UserAddEditView-Status" id="UserAddEditView-StatusLabel" class="required">Status</label>
      <div class="flex-group" id="UserAddEditView-Status">
        @for (key of statusDisplayKeys; track key) {
          <div>
            <input
              type="radio"
              name="UserAddEditView-StatusName"
              [value]="key"
              [formControl]="controls.status"
              [id]="'UserAddEditView-' + idFormatterService.toInterCaps(key) + 'Radio'"
            />
            <label class="radio-label" [for]="'UserAddEditView-' + idFormatterService.toInterCaps(key) + 'Radio'">{{
              statusDisplay[key]
            }}</label>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #buttons_>
  <div class="buttons">
    <button
      id="UserAddEditView-SaveButton"
      class="save-button"
      mat-flat-button
      [disabled]="!(form.valid && form.dirty)"
      color="primary"
      (click)="actionSave()"
    >
      Save
    </button>
    <button
      id="UserAddEditView-CancelButton"
      class="cancel-button"
      mat-stroked-button
      color="primary"
      [routerLink]="usersPath"
    >
      Cancel
    </button>
  </div>
</ng-template>

@if (busy()) {
  <app-loading-indicator></app-loading-indicator>
}
