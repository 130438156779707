import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { versionInfo } from '../../../../versionInfo';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<AboutDialogComponent>);

  version = versionInfo.version;
  buildDate = versionInfo.buildDate;

  onCloseClick() {
    this.dialogRef.close();
  }
}
