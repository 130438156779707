export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export type StatusKey = keyof typeof Status;

export const StatusDisplay: Record<StatusKey, string> = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};
