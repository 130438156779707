@if (tenant) {
  <div class="user-management">
    <h1 id="UserManagement-ManageUsersLabel">Manage Users - {{ tenant.tenant_name }}</h1>
    <button mat-button color="primary" [routerLink]="['/', routePath.institutions]" id="UserManagement-BackButton">
      <span class="icon-arrow-left2"></span
      ><span id="UserManagement-BackButtonLabel">&nbsp;Back to Institution List</span>
    </button>
    <div class="add-button">
      <button id="UserManagement-AddUserButton" mat-flat-button color="primary" (click)="actionAddUser()">
        Add User
      </button>
    </div>
    <div [ngClass]="isLoading || isEmpty ? 'hidden' : ''">
      <app-table [config]="config" describedBy="UserManagement-ManageUsersLabel"></app-table>
    </div>
    <div [ngClass]="isLoading || !isEmpty ? 'hidden' : 'empty-message'" id="UserManagement-EmptyMessageLabel">
      <p>
        <strong>Welcome</strong> to the Werfen Cloud User Management page. Here you can view, edit, and create users.
      </p>
      <p>
        To create your first user, click on the <strong>“Add User”</strong> button in the upper right-hand corner of the
        page.
      </p>
    </div>
  </div>
}
