import { Component, inject, OnInit } from '@angular/core';
import { BaseFormDirective } from '../../shared/base-form/base-form.directive';
import { MatDialog } from '@angular/material/dialog';
import { TenantsService } from '../../../services/tenants.service';
import {
  ConfirmationDialogComponent,
  IdFormatterService,
  NotificationService,
  Severity,
} from '@digital-factory/ds-common-ui';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ADD_EDIT_VIEW_NODES,
  AddEditViewMode,
} from '../../institution-management/institution-add-edit-view/institution-add-edit-view';
import { UserAddEditViewParams } from './user-add-edit-view';
import { ROUTE_PATH } from '../../app-routing';
import { UsersService } from '../../../services/users.service';
import { STATUS, STATUS_DISPLAY, StatusKey } from '../../../info-model/enums/status';
import { Validators } from '@angular/forms';
import { EMAIL_REGEX, NAME_REGEX } from '../../../info-model/constants/form-validators.constants';
import { finalize, Observable } from 'rxjs';
import { Application } from '../../../info-model/application';
import { ApplicationsService } from '../../../services/applications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Tenant } from '../../../info-model/tenant';
import { User } from '../../../info-model/user';

@Component({
  templateUrl: './user-add-edit-view.component.html',
  styleUrl: '../../institution-management/institution-add-edit-view/institution-add-edit-view.component.scss',
})
export class UserAddEditViewComponent extends BaseFormDirective<User> implements OnInit {
  application!: Application;
  readonly error = {
    required: 'Required Field',
  };
  fullName!: string;
  readonly idFormatterService = inject(IdFormatterService);
  mode!: AddEditViewMode;
  readonly routePath = ROUTE_PATH;
  readonly statusDisplay = STATUS_DISPLAY;
  readonly statusDisplayKeys: StatusKey[] = Object.keys(STATUS_DISPLAY) as StatusKey[];
  roleDisplay: Record<string, string> = {};
  roleDisplayKeys: string[] = [];
  tenant!: Tenant;
  title!: string;
  user!: User;
  usersPath!: Array<string | object>;

  private readonly applicationsService = inject(ApplicationsService);
  private readonly usersService = inject(UsersService);
  private readonly dialog = inject(MatDialog);
  private readonly institutionsService = inject(TenantsService);
  private readonly notificationService = inject(NotificationService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  actionSave() {
    const { mode, usersService, rawValue, router, notificationService, form, user, usersPath, busy } = this;
    let request!: Observable<string>;

    rawValue.tenant_id = user.tenant_id;

    if (mode === 'add') {
      request = usersService.createUser$(rawValue);
    } else {
      request = usersService.updateUser$(rawValue, true);
    }

    busy.set(true); // httpCallHandlerService is not being  used, so put up the loading indicator.

    request.pipe(finalize(() => busy.set(false))).subscribe({
      next: () => {
        const message =
          `The user "${rawValue.first_name.trim()} ${rawValue.last_name.trim()}" has been ` +
          (mode === 'edit' ? 'updated' : 'added');

        form.markAsPristine();
        notificationService.showMessage(message, Severity.Success);

        if (mode === 'add') {
          usersPath.push({ user_id: rawValue.user_name });
        }
        router.navigate(usersPath);
      },
      error: (e: HttpErrorResponse) => notificationService.showMessage(e.error, Severity.Error),
    });
  }

  canDeactivate(): boolean | Observable<boolean> {
    const { form, dialog } = this;

    if (form.dirty) {
      return dialog
        .open(ConfirmationDialogComponent, {
          data: {
            title: 'Leave User',
            message: 'Are you sure you want to leave this user without saving your edits?',
            cancelButtonText: 'Cancel',
            okButtonText: 'Yes',
            okButtonId: 'Yes',
          },
        })
        .afterClosed();
    }

    return true;
  }

  ngOnInit() {
    const { applicationsService } = this;

    applicationsService.initialize().subscribe(() => this.initialize());
  }

  private getTenant(tenantId: string, user_id: string) {
    const { institutionsService, usersService, mode, applicationsService } = this;

    institutionsService.getTenant$(tenantId).subscribe((tenant: Tenant) => {
      this.tenant = tenant;
      this.usersPath = usersService.navigate(tenant.tenant_id);
      this.application = applicationsService.get(tenant.application);
      this.roleDisplay = applicationsService.kvsToRecord(this.application.user_roles);
      this.roleDisplayKeys = Object.keys(this.roleDisplay);

      if (mode === 'add') {
        this.initializeUser();
      } else {
        this.usersPath.push({ user_id });
        this.getUser(user_id);
      }
    });
  }

  private getUser(user_id: string) {
    const { usersService, tenant } = this;

    usersService.getUser$(tenant.tenant_id, user_id).subscribe((user: User) => {
      this.user = user;
      this.initializeForm();
    });
  }

  private initialize() {
    const { route, router, destroyRef } = this;

    route.params.pipe(takeUntilDestroyed(destroyRef)).subscribe((p: Params) => {
      const params = p as UserAddEditViewParams;

      if (ADD_EDIT_VIEW_NODES.includes(params.mode)) {
        this.mode = params.mode;
        switch (this.mode) {
          case 'add':
            this.title = 'Add User';
            break;
          case 'edit':
            this.title = 'Update User';
            break;
          case 'view':
            this.title = 'View User (Read Only)';
            break;
        }
        this.getTenant(params.tenantId, params.userId);
      } else {
        router.navigate([ROUTE_PATH.error, '404']);
      }
    });
  }

  private initializeUser() {
    const { application, tenant } = this;

    this.user = {
      department: '',
      user_name: '',
      first_name: '',
      last_name: '',
      links: [],
      role: application.user_roles_default,
      status: STATUS.ACTIVE,
      tenant_id: tenant.tenant_id,
    };

    this.initializeForm();
  }

  private initializeForm() {
    const { mode, user, destroyRef } = this;
    const disabled = mode === 'view';
    const { required } = Validators;

    this.formCreate({
      department: [{ value: user.department, disabled }, [Validators.pattern(NAME_REGEX)]],
      user_name: [{ value: user.user_name, disabled: mode !== 'add' }, [required, Validators.pattern(EMAIL_REGEX)]],
      first_name: [{ value: user.first_name, disabled }, [required, Validators.pattern(NAME_REGEX)]],
      last_name: [{ value: user.last_name, disabled }, [required, Validators.pattern(NAME_REGEX)]],
      role: [{ value: user.role, disabled }, required],
      status: [{ value: user.status, disabled }, required],
      tenant_id: [{ value: user.tenant_id, disabled: true }],
      links: [{ value: user.links, disabled: disabled }],
    });

    const {
      controls: { first_name, last_name },
    } = this;
    const fullName = () => {
      const name = (first_name.value.trim() + ' ' + last_name.value.trim()).trim();
      this.fullName = name || 'New User';
    };

    for (const control of [first_name, last_name]) {
      control.valueChanges.pipe(takeUntilDestroyed(destroyRef)).subscribe(() => fullName());
    }

    fullName();

    if (mode === 'view') {
      this.form.disable();
    }
  }
}
