import { DestroyRef, Directive, inject, signal, WritableSignal } from '@angular/core';

/**
 * rxjs: destroyRef, busy: signal<boolean>
 */
@Directive()
export class BaseSubscriptionsDirective {
  readonly busy: WritableSignal<boolean> = signal(false);
  readonly destroyRef = inject(DestroyRef);
}
