export enum STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export type StatusKey = keyof typeof STATUS;

export const STATUS_DISPLAY: Record<StatusKey, string> = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};
