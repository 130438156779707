import { KeyValue } from '@angular/common';

export interface Application {
  countries: KeyValue<string, string>[];
  countries_default: string;
  date_formats: KeyValue<string, string>[];
  date_formats_default: string;
  id: number;
  languages: KeyValue<string, string>[];
  languages_default: string;
  multiple_locations: boolean;
  name: KeyValue<string, string>;
  number_formats: KeyValue<string, string>[];
  number_formats_default: string;
  options: KeyValue<string, string>[];
  regulatory_entities: KeyValue<string, string>[];
  regulatory_entities_default: string;
  tiers: KeyValue<string, string>[];
  tiers_default: string;
  time_zones: KeyValue<string, string>[];
  time_zones_default: string;
  user_roles: KeyValue<string, string>[];
  user_roles_default: string;
}

/** .../v1/appconfig */
export interface ServerApplication {
  countries: Record<string, string>;
  countries_order: string[];
  date_formats: Record<string, string>;
  date_formats_order: string[];
  id: number;
  languages: Record<string, string>;
  languages_order: string[];
  multiple_locations: boolean;
  name: Record<string, string>;
  number_formats: Record<string, string>;
  number_formats_order: string[];
  options: Record<string, string>;
  options_order: string[];
  regulatory_entities: Record<string, string>;
  regulatory_entities_order: string[];
  tiers: Record<string, string>;
  tiers_order: string[];
  time_zones: Record<string, string>;
  time_zones_order: string[];
  user_roles: Record<string, string>;
  user_roles_order: string[];
}

export const APPLICATION_ROLE_WERFEN_ADMIN = 'WERFEN_CLOUD_ADMINISTRATOR';
