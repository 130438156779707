import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, Signal, ViewChild, inject } from '@angular/core';
import { EventType, Router } from '@angular/router';
import { ActionIcon, AuthorizationService, HttpCallHandlerService } from '@digital-factory/ds-common-ui';
import { Subscription } from 'rxjs';

import { PageName } from 'src/info-model/enums/pageName';
import { routePath } from '../app-routing';
import { ProfileBarComponent } from './profile-bar/profile-bar.component';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms ease-out', style({ opacity: 1 }))]),
      transition(':leave', [animate('200ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class FrameComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly authorizationService = inject(AuthorizationService);

  // gets a series of letters after a "/" or "\"
  private readonly URL_PATH_PART_REGEX = /(?<=[\\/])[a-zA-Z]+/;
  private readonly pageMap: { [key in PageName]: ActionIcon } = {
    INSTITUTIONS: {
      label: 'Institutions',
      icon: 'icon-table',
      callback: (_) => this.router.navigate(['/' + routePath.institutions]),
    },
  };

  @ViewChild(ProfileBarComponent) profileBar!: ProfileBarComponent;

  private authSubscription = Subscription.EMPTY;
  private routeSubscription = Subscription.EMPTY;

  isWaiting: Signal<boolean>;
  isAuthorized = this.authorizationService.isAuthorized();
  icons: ActionIcon[] = [];
  selectedIcon = this.pageMap[PageName.INSTITUTIONS];

  constructor(httpCallHandlerService: HttpCallHandlerService) {
    this.isWaiting = httpCallHandlerService.isCalling;

    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event.type === EventType.NavigationEnd) {
        const pathParts = this.URL_PATH_PART_REGEX.exec(event.url);
        if (pathParts) {
          const pageName = pathParts[0].toUpperCase() as PageName;
          if (Object.keys(PageName).includes(pageName)) {
            this.selectedIcon = this.pageMap[pageName];
          } else {
            this.selectedIcon = this.pageMap[PageName.INSTITUTIONS];
          }
        }
      }
    });
  }

  ngOnInit() {
    const currentUser = this.authorizationService.getCurrentUser();
    if (currentUser == null) {
      this.authSubscription = this.authorizationService.currentUser$.subscribe((user) => {
        if (user) {
          this.setPages();
          this.authSubscription.unsubscribe();
        }
      });
    } else {
      this.setPages();
    }
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }

  private setPages() {
    //will need to take a parameter of user when HATEOAS info is ready
    this.isAuthorized = true;

    // Will need to set the allowed pages/operations based on HATEOAS links the user would have once available
    if (!this.icons.includes(this.pageMap[PageName.INSTITUTIONS])) {
      this.icons.push(this.pageMap[PageName.INSTITUTIONS]);
    }
  }
}
