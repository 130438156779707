import { inject, Injectable } from '@angular/core';
import { Application, ServerApplication } from '../info-model/application';
import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  private readonly httpClient = inject(HttpClient);
  private readonly urlService = inject(UrlService);
  private _applications?: Application[];

  get applications(): Readonly<Readonly<Application>[]> {
    if (!this._applications) {
      throw new Error(`Call initialize() first`);
    }

    return this._applications;
  }

  get names(): Readonly<KeyValue<string, string>>[] {
    return this.applications.map((a) => a.name);
  }

  get namesRecord(): Record<string, string> {
    return this.kvsToRecord(this.applications.map((a) => a.name));
  }

  get(name: string): Readonly<Application> {
    const result = this.applications.find((application) => application.name.key === name);

    if (!result) {
      throw new Error(`Application not found: ${name}`);
    }

    return result;
  }

  /**
   * This *MUST* be called *BEFORE* accessing other methods. this.init() id called once.
   */
  initialize(): Observable<boolean> {
    if (this._applications) {
      return of(true);
    } else {
      return this.init();
    }
  }

  kvsToRecord(kvs: KeyValue<string, string>[]): Record<string, string> {
    const result: Record<string, string> = {};

    for (const kv of kvs) {
      result[kv.key] = kv.value;
    }

    return result;
  }

  /**
   * Called once via this.initialize.
   */
  private init(): Observable<boolean> {
    const { httpClient, urlService } = this;
    const toKVs = (order: string[], record: Record<string, string>) => {
      let result: KeyValue<string, string>[] = [];

      if (Array.isArray(order) && typeof record === 'object') {
        result = order.map((key) => ({
          key,
          value: record[key] || '???',
        }));
      }

      return result;
    };
    const defaultValue = (record: Record<string, string>) => (record ? record['default'] || '' : '');

    return httpClient.get<ServerApplication[]>(urlService.getApiUrl('appconfig')).pipe(
      catchError((error) => {
        throw new Error(`appConfig failed: ${error.toString()}`);
      }),
      map((recs: ServerApplication[]) => {
        return recs.map((rec: ServerApplication) => {
          const {
            date_formats,
            date_formats_order,
            id,
            languages,
            languages_order,
            multiple_locations,
            name,
            number_formats,
            number_formats_order,
            options,
            options_order,
            regulatory_entities,
            regulatory_entities_order,
            tiers,
            tiers_order,
            time_zones,
            time_zones_order,
            user_roles,
            user_roles_order,
          } = rec;

          return {
            date_formats: toKVs(date_formats_order, date_formats),
            date_formats_default: defaultValue(date_formats),
            id,
            languages: toKVs(languages_order, languages),
            languages_default: defaultValue(languages),
            multiple_locations,
            name: toKVs(Object.keys(name), name)[0],
            number_formats: toKVs(number_formats_order, number_formats),
            number_formats_default: defaultValue(number_formats),
            options: toKVs(options_order, options),
            regulatory_entities: toKVs(regulatory_entities_order, regulatory_entities),
            regulatory_entities_default: defaultValue(regulatory_entities),
            tiers: toKVs(tiers_order, tiers),
            tiers_default: defaultValue(tiers),
            time_zones: toKVs(time_zones_order, time_zones),
            time_zones_default: defaultValue(time_zones),
            user_roles: toKVs(user_roles_order, user_roles),
            user_roles_default: defaultValue(user_roles),
          };
        });
      }),
      map((recs: Application[]) => {
        this._applications = recs.sort((a, b) => a.id - b.id);
        return true;
      }),
    );
  }
}
