<div #dialog class="dialog">
  <div class="header">
    <div class="icon-cloud"></div>
    <h1 class="title" id="AboutDialog-DialogTitleLabel">Werfen Cloud</h1>
    <div class="icon-cancel-circle" autofocus (click)="onCloseClick()" id="AboutDialog-XButton"></div>
  </div>
  <div mat-dialog-content class="content">
    <div class="labeling">
      <div>
        <div id="AboutDialog-SoftwareVersionLabel"><strong>Software Version:</strong> {{ version }}</div>
        <div id="AboutDialog-SoftwareBuildDateLabel"><strong>Software Build Date:</strong> {{ buildDate }}</div>
        <div class="license">
          <div class="boxed-text" id="AboutDialog-UdiLabel">UDI</div>
          <div id="AboutDialog-DeviceIdentifierLabel">DGF-PLT1</div>
        </div>
      </div>
      <div class="eifu">
        <div class="eifu-icon"></div>
        <a href="https://werfen.com" class="eifu-link" target="_blank" rel="noopener" id="AboutDialog-EifuLink"
          >https://www.werfen.com</a
        >
      </div>
      <div>
        <div class="label-title" id="AboutDialog-DistributedByWerfenNoaLabel">Distributed by Werfen NoA:</div>
        <div id="AboutDialog-WerfenAddressLabel">
          180 Hartwell Road<br />
          Bedford, MA 01730-2443 USA<br />
          1-800-955-9525<br />
          customerservice.na&#64;werfen.com
        </div>
      </div>
      <div class="manufacturer">
        <div class="manufacturer-icon"></div>
        <div class="label-title" id="AboutDialog-SystelabTechnologiesSALabel">Systelab Technologies, S.A.</div>
        <div id="AboutDialog-ManufacturerAddressLabel">
          Can Montcau, 7<br />
          08186 Lliçá d`Amunt<br />
          Barcelona, Spain
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</div>
