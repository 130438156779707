import { Directive, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseSubscriptionsDirective } from '../base-subscriptions/base-subscriptions.directive';
import { BaseFormOptions } from './base-form.model';

/**
 * Not an Angular Component, but a base class for safe form building
 */
@Directive()
export class BaseFormDirective<FormModel extends object, FormValue = FormModel> extends BaseSubscriptionsDirective {
  readonly fb = inject(FormBuilder);
  form!: FormGroup;

  /**
   * @return typesafe form control
   */
  get controls(): Record<keyof FormModel, FormControl> {
    return this.form.controls as Record<keyof FormModel, FormControl>;
  }

  /**
   * @return typesafe form.value, which will contain all the control values
   */
  get formValue(): FormValue {
    return this.form.value as FormValue;
  }

  /**
   * @return like formValue() but will include disabled values
   */
  get rawValue(): FormValue {
    return this.form.getRawValue() as FormValue;
  }

  /**
   * Typesafe form creation
   *
   * @param model safe form data
   * @param options optional: nonNullable or all required
   */
  formCreate(model: FormModel | Record<keyof FormModel, unknown>, options?: Partial<BaseFormOptions>): void {
    const { fb } = this;

    this.form = options?.nonNullable ? fb.nonNullable.group(model) : fb.group(model);
    this.form.markAsPristine();

    if (options?.allRequired) {
      const { controls } = this.form;

      Object.keys(controls).forEach((key: string) => {
        controls[key].addValidators(Validators.required);
      });
    }
  }

  hasError(control: FormControl, errorName: string): boolean {
    const state = errorName === 'invalid' ? control.invalid : control.hasError(errorName);
    return state && control.touched;
  }

  patchValue(value: Partial<FormModel>, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    this.form.patchValue(value, options);
  }
}
