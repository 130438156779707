export enum Login {
  LOCAL = 'LOCAL',
  SSO = 'SSO',
}

export type LoginKey = keyof typeof Login;
export const LoginDisplay: Record<LoginKey, string> = {
  LOCAL: 'Local',
  SSO: 'SSO',
};
