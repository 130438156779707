<div class="app-container">
  <header>
    <app-profile-bar></app-profile-bar>
  </header>
  <nav>
    <app-navigation-bar [icons]="icons" [selectedIcon]="selectedIcon"></app-navigation-bar>
  </nav>
  <div>
    <router-outlet></router-outlet>
  </div>
  <footer>
    <div class="copyright">© 2024 Werfen. All Rights Reserved.</div>
  </footer>
</div>

@if (!isAuthorized) {
  <div class="overlay"></div>
}

@if (!isAuthorized || isWaiting()) {
  <app-loading-indicator></app-loading-indicator>
}
