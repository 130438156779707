import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { DsCommonUiModule } from '@digital-factory/ds-common-ui';
import { UsersService } from '../../services/users.service';
import { UserManagementComponent } from './user-management.component';
import { UserAddEditViewComponent } from './user-add-edit-view/user-add-edit-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [UserManagementComponent, UserAddEditViewComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    DsCommonUiModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [UsersService],
})
export class UserManagementModule {}
