import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { HttpCallHandlerService } from '@digital-factory/ds-common-ui';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UrlService } from './url.service';
import { Router } from '@angular/router';
import { routePath } from '../app/app-routing';
import { Tenant, TenantCreateUpdate } from '../info-model/tenant';
import { Link } from '../info-model/link';

interface AllTenants {
  tenants: Tenant[];
  links: Link[];
}

@Injectable()
export class TenantsService {
  private readonly httpClient = inject(HttpClient);
  private readonly httpCallHandlerService = inject(HttpCallHandlerService);
  private readonly urlService = inject(UrlService);
  private readonly router = inject(Router);

  private methodToHrefForTenants = new Map<string, string>();
  private tenantIdToLinksMap = new Map<string, Map<string, string>>();

  getTenants$(errHandler?: () => void): Observable<Tenant[]> {
    const result = this.httpClient.get<AllTenants>(this.urlService.getApiUrl('tenants')).pipe(
      tap((res) => {
        this.methodToHrefForTenants = this.urlService.getLinksMap(res.links);
        res.tenants.forEach((tenant: Tenant) =>
          this.tenantIdToLinksMap.set(tenant.tenant_id!, this.urlService.getLinksMap(tenant.links)),
        );
      }),
      map((res) => res.tenants),
    );

    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorHandler: () => {
        if (errHandler) {
          errHandler();
        }
        return 'Loading institutions failed.';
      },
    });
  }

  createTenant$(tenant: TenantCreateUpdate) {
    const url = this.methodToHrefForTenants.get('POST/new_tenant')?.toString() ?? '';
    const result = this.httpClient.post(url, JSON.stringify(tenant), {
      responseType: 'text',
    });

    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorMessage: 'An error occurred. The institution could not be created.',
    });
  }

  getTenant$(tenantId: string): Observable<Tenant> {
    const url = `${this.urlService.getApiUrl('tenants')}/${tenantId}`;
    const result = this.httpClient.get<Tenant>(url);

    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorHandler: () => {
        this.router.navigate([routePath.error, '400']);
        return `An error occurred. Institution not found: ${tenantId}`;
      },
    });
  }

  /**
   * Update a tenant. The URL is dependent on finding the appropriate PUT link inside the tenant
   *
   * @param tenant TenantAddUpdate
   * @param links from the GET, the PUT link crafts the URL
   */
  updateTenant$(tenant: TenantCreateUpdate, links: Link[]) {
    const url = links.find((link) => link.type === 'PUT')?.href || ''; // tenant.links *MUST have an entry with type='PUT'

    this.urlService.validateUrl(url, 'Update link for tenant ' + tenant.tenant_name + ' is not valid');

    const result = this.httpClient.put(url, JSON.stringify(tenant), { responseType: 'text' });
    return this.httpCallHandlerService.handleErrors(result, {
      defaultErrorMessage: 'An error occurred. The institution could not be updated.',
    });
  }

  deleteTenant$(tenantId: string, tenantName: string): Observable<string> {
    const url = this.tenantIdToLinksMap.get(tenantId)?.get('DELETE/self') ?? '';
    //User both tenant name and id in case tenantId is empty
    this.urlService.validateUrl(url, 'Delete link for tenant ' + tenantName + ' (' + tenantId + ') is not valid');

    return this.httpClient.delete(url, { responseType: 'text' });
  }
}
