import { Location } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '@digital-factory/ds-common-ui';
import { ROUTE_PATH } from '../app-routing';

type ErrorType = '400' | '401' | '403' | '404' | '500';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  errorId!: ErrorType;
  errorMessage!: string;
  redirectPath!: string;
  readonly text = {
    error: 'Error',
    buttonDefaultPage: 'Go to Institutions',
    buttonLoginPage: 'Go to Login page',
    button: '',
    copyright: 'Copyright 2024',
    support: 'Please contact your IT support associate if you continue seeing this error.',
  };

  private readonly location = inject(Location);
  private readonly authorizationService = inject(AuthorizationService);
  private readonly route = inject(ActivatedRoute);
  private readonly destroyRef = inject(DestroyRef);
  private readonly errors: Record<ErrorType, string> = {
    400: 'Sorry, the parameters to this link are invalid',
    401: 'Sorry, you are not authorized to perform that action.',
    403: 'Sorry, you do not have permissions to perform that action.',
    404: 'Sorry, that link is invalid and the page cannot be reached.',
    500: 'Unknown error.',
  };

  constructor() {
    const { errors, destroyRef, route, authorizationService, location } = this;

    if (authorizationService.isAuthorized()) {
      this.redirectPath = '/' + ROUTE_PATH.institutions;
      this.text.button = this.text.buttonDefaultPage;
    } else {
      this.redirectPath = ''; //This will redirect user to login page as user hasn't authorized yet
      location.replaceState(''); // replace location state to empty to make sure routing won't redirect to the orignial wrong URL again after login
      this.text.button = this.text.buttonLoginPage;
    }

    route.params.pipe(takeUntilDestroyed(destroyRef)).subscribe((params) => {
      const id: ErrorType = params['id'] || '500';

      this.errorId = id;
      this.errorMessage = errors[id] || errors['500'];
    });
  }
}
