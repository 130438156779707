<div class="institution-management">
  <h1 id="InstitutionManagement-ManageInstitutionsLabel">Manage Institutions</h1>
  <div class="add-button">
    <button
      mat-flat-button
      color="primary"
      (click)="actionAddInstitution()"
      id="InstitutionManagement-AddInstitutionButton"
    >
      Add Institution
    </button>
  </div>
  <div [ngClass]="isLoading || isEmpty ? 'hidden' : ''">
    <app-table [config]="config" describedBy="InstitutionManagement-ManageInstitutionsLabel"></app-table>
  </div>
  <div [ngClass]="isLoading || !isEmpty ? 'hidden' : 'empty-message'" id="InstitutionManagement-EmptyMessageLabel">
    <p>
      <strong>Welcome</strong> to the Werfen Cloud Institution Management page. Here you can view, edit, and create
      institutions.
    </p>
    <p>
      To create your first institution, click on the <strong>“Add Institution”</strong> button in the upper right-hand
      corner of the page.
    </p>
  </div>
</div>
