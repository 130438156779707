import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';

import { ProfileBarComponent } from './profile-bar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AboutDialogModule } from './about-dialog';

@NgModule({
  declarations: [ProfileBarComponent],
  exports: [ProfileBarComponent],
  imports: [CommonModule, BrowserAnimationsModule, MatMenuModule, MatDialogModule, AboutDialogModule],
})
export class ProfileBarModule {}
