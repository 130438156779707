import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DsCommonUiModule } from '@digital-factory/ds-common-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TenantsService } from 'src/services/tenants.service';
import { InstitutionAddEditViewComponent } from './institution-add-edit-view/institution-add-edit-view.component';
import { InstitutionManagementComponent } from './institution-management.component';
import { RouterModule } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [InstitutionManagementComponent, InstitutionAddEditViewComponent],
  exports: [InstitutionManagementComponent, InstitutionAddEditViewComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    DsCommonUiModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [TenantsService],
})
export class InstitutionManagementModule {}
