import { inject, Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Link } from 'src/info-model/link';
import { NotificationService, Severity } from '@digital-factory/ds-common-ui';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  readonly urlPrefix = environment.urlPrefix === '' ? '' : `${environment.urlPrefix}.`;
  private readonly notificationService = inject(NotificationService);

  getApiUrl(apiName: string): string {
    return `https://api.${this.urlPrefix}werfencloud.com/stratus-platform-management/v1/${apiName}`;
  }

  getLinksMap(links: Link[]): Map<string, string> {
    const linksMap = new Map<string, string>();
    links.forEach((link) => linksMap.set(link.type + '/' + link.rel, link.href));
    return linksMap;
  }

  /**
   * Throw an Error if url either blank or non-string
   * @param url string
   * @param errorMessage message in Error
   */
  validateUrl(url: string | unknown, errorMessage: string) {
    if (typeof url !== 'string' || url.trim().length == 0) {
      this.notificationService.showMessage(
        'A data error occurred. Please contact your help desk or IT support associate.',
        Severity.Error,
      );
      throw new Error(errorMessage);
    }
  }
}
