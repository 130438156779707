import { Link } from './link';
import { LOGIN } from './enums/login';
import { STATUS } from './enums/status';

export interface Tenant {
  application: string;
  client_id: string;
  client_secret: string;
  contact_email: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_phone: string;
  country: string;
  customer_id: string;
  date_format: string;
  gateway_key: string;
  language: string;
  links: Link[];
  locations: TenantLocation[];
  login_type: LOGIN;
  number_format: string;
  region: string;
  regulatory_entity: string;
  status: STATUS;
  tenant_id: string;
  tenant_name: string;
  tenant_type?: string; // used only by the b/e
}

export interface TenantLocation {
  license_expiration_date: string;
  license_options: string[];
  license_start_date: string;
  time_zone: string;
  location_name: string /** for GWL, this should be tenant_id, should be on exclusion list for GWL update  */;
  tier: string;
}

/** used for POST/PUT  */
export type TenantCreateUpdate = Omit<Tenant, 'client_id' | 'client_secret' | 'tenant_type' | 'links' | 'tenant_id'>;
export const TENANT_NULLABLE_FIELDS: Array<keyof Tenant> = [
  'contact_email',
  'contact_firstname',
  'contact_lastname',
  'contact_phone',
];
