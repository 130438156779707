import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { AboutDialogComponent } from './about-dialog.component';

@NgModule({
  declarations: [AboutDialogComponent],
  imports: [BrowserModule, FormsModule, MatButtonModule, MatDialogModule],
  providers: [],
  bootstrap: [AboutDialogComponent],
  exports: [AboutDialogComponent],
})
export class AboutDialogModule {}
