import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmationDialogComponent,
  NotificationService,
  Severity,
  TableComponent,
} from '@digital-factory/ds-common-ui';
import { TableConfiguration } from '@digital-factory/ds-common-ui/lib/info-model/table-configuration';
import { asyncScheduler, EMPTY, finalize, map, Observable, scheduled, filter, switchMap } from 'rxjs';

import { Login, LoginDisplay } from 'src/info-model/enums/login';
import { StatusDisplay } from 'src/info-model/enums/status';
import { TenantsService } from 'src/services/tenants.service';
import { routePath } from '../app-routing';
import { InstitutionAddEditViewParams } from './institution-add-edit-view/institution-add-edit-view';
import { UsersService } from '../../services/users.service';
import { ApplicationsService } from '../../services/applications.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BaseSubscriptionsDirective } from '../shared/base-subscriptions/base-subscriptions.directive';
import { GenericRecord } from '../../info-model/generic';
import { Tenant } from '../../info-model/tenant';

@Component({
  selector: 'app-institution-management',
  templateUrl: './institution-management.component.html',
  styleUrls: ['./institution-management.component.scss'],
})
export class InstitutionManagementComponent extends BaseSubscriptionsDirective implements OnInit, AfterViewInit {
  @ViewChild(TableComponent) table!: TableComponent;

  config: TableConfiguration = {
    name: 'InstitutionManagement-InstitutionsTable',
    pageSize: 10,
    dataSource: EMPTY,
    columns: [],
    actions: [],
    emptyMessage: 'No institutions match the search criteria',
  };

  isEmpty = true;
  isLoading = true;
  tenant_name!: string;

  private readonly applicationsService = inject(ApplicationsService);
  private readonly institutionsService = inject(TenantsService);
  private readonly dialog = inject(MatDialog);
  private readonly location = inject(Location);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly notificationService = inject(NotificationService);
  private readonly usersService = inject(UsersService);

  actionAddInstitution() {
    const params: Partial<InstitutionAddEditViewParams> = {
      mode: 'add',
    };
    this.router.navigate([routePath.institutions, '0', params]);
  }

  //Use ngAfterViewInit to avoid error of 'Expression has changed after it was checked' in unit tests
  ngAfterViewInit(): void {
    const { applicationsService } = this;

    applicationsService.initialize().subscribe(() => this.initialize());
  }

  ngOnInit() {
    const { destroyRef, route, location } = this;

    route.params.pipe(takeUntilDestroyed(destroyRef)).subscribe((p) => {
      const tenant_name = p['tenant_name'];

      if (typeof tenant_name === 'string') {
        location.replaceState(`/${routePath.institutions}`);
        // TODO put in when ticket created
        this.tenant_name = tenant_name.trim();
      }
    });
  }

  delete(tenant: Tenant) {
    const { dialog, institutionsService, notificationService, destroyRef } = this;
    const { tenant_name, tenant_id } = tenant;

    dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: 'Delete Institution',
          message: `Are you sure you want to delete ${tenant_name}?`,
          cancelButtonText: 'Cancel',
          okButtonText: 'Delete',
          okButtonId: 'Delete',
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        switchMap(() => institutionsService.deleteTenant$(tenant_id, tenant_name)),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe({
        next: () => {
          this.refreshTable();
          notificationService.showMessage('The institution was deleted.', Severity.Success);
        },
        error: (error: HttpErrorResponse) => {
          const message =
            error.status === 409
              ? 'The selected Institution could not be deleted because it has Users added to it. Please delete all the users first.'
              : `The institution could not be deleted. ${error.error}.`;
          notificationService.showMessage(message, Severity.Error);
        },
      });
  }

  refreshTable() {
    this.table.selected = null;
    this.config = {
      ...this.config,
      dataSource: this.getData(true),
    };
  }

  private initialize() {
    const { router, usersService, applicationsService } = this;
    const toTenant = (element: unknown) => element as Tenant;

    this.config = {
      ...this.config,
      dataSource: this.getData(true),
      columns: [
        {
          key: 'tenant_name',
          label: 'Institution Name',
          isSortable: true,
        },
        {
          key: 'customer_id',
          label: 'Customer ID',
        },
        {
          key: 'contact_email',
          label: 'E-mail',
        },
        {
          key: 'contact_firstname',
          label: 'Name',
        },
        {
          key: 'contact_phone',
          label: 'Phone',
        },
        {
          key: 'application',
          label: 'Application',
          valueMap: applicationsService.namesRecord,
        },
        {
          key: 'status',
          label: 'Status',
          valueMap: StatusDisplay,
        },
        {
          key: 'login_type',
          label: 'Login',
          valueMap: LoginDisplay,
        },
        {
          key: 'license_type',
          label: 'Tier',
        },
      ],
      actions: [
        {
          label: 'View Institution',
          icon: 'icon-eye',
          isDisabled: (_) => false,
          callback: (element) => {
            if (element) {
              const params: Partial<InstitutionAddEditViewParams> = {
                mode: 'view',
              };
              router.navigate([routePath.institutions, toTenant(element).tenant_id, params]);
            }
          },
        },
        {
          label: 'Update Institution',
          icon: 'icon-pencil',
          isDisabled: (_) => false,
          callback: (element) => {
            if (element) {
              const params: Partial<InstitutionAddEditViewParams> = {
                mode: 'edit',
              };
              const row = element as unknown as Tenant;
              router.navigate([routePath.institutions, row.tenant_id, params]);
            }
          },
        },
        {
          label: 'Manage Users',
          icon: 'icon-users',
          isDisabled: (element) => element['login_type'] === Login.SSO,
          callback: (element) => {
            if (element) {
              const row = element as unknown as Tenant;
              router.navigate(usersService.navigate(row.tenant_id));
            }
          },
        },
        {
          label: 'Delete Institution',
          icon: 'icon-trash',
          isDisabled: (_) => false,
          callback: (element) => {
            if (element) {
              this.delete(toTenant(element));
            }
          },
        },
      ],
    };
    this.config.defaultSortColumn = this.config.columns[0].key;
  }

  private mapTenantsToRows = (tenants: Tenant[]): GenericRecord[] => {
    const { applicationsService } = this;

    this.isEmpty = tenants.length === 0;
    this.isLoading = false;

    for (const tenant of tenants) {
      const application = applicationsService.get(tenant.application);
      const loc = tenant.locations[0];
      const tier = application.multiple_locations ? null : application.tiers.find((tier) => tier.key === loc.tier);

      (tenant as unknown as GenericRecord)['license_type'] = tier ? tier.value : '-';
      tenant.contact_firstname += ` ${tenant.contact_lastname}`;
    }

    return tenants as unknown as GenericRecord[];
  };

  private onError() {
    this.config = {
      ...this.config,
      dataSource: this.getData(false),
    };
  }

  private getData(shouldLoadData: boolean): Observable<GenericRecord[]> {
    if (shouldLoadData) {
      let rx: Tenant[] = [];

      return this.institutionsService
        .getTenants$(() => this.onError())
        .pipe(
          map((rows) => {
            rx = rows;
            return this.mapTenantsToRows(rows);
          }),
          finalize(() => {
            const { tenant_name } = this;

            if (tenant_name) {
              this.tenant_name = '';
              setTimeout(() => {
                const { table } = this;
                if (table) {
                  const row = rx.find((r) => r.tenant_name === tenant_name);

                  if (row) {
                    table.filterControl.setValue(row.tenant_name);
                  }
                }
              }, 500);
            }
          }),
        );
    } else {
      this.isEmpty = true;
      this.isLoading = false;
      return scheduled([], asyncScheduler);
    }
  }
}
