@if (errorId) {
  <div class="error-page">
    <header>
      <div class="werfen-logo"></div>
    </header>
    <div class="sidebar"></div>
    <div class="content">
      <div class="error-content">
        <h1 class="error-header" id="Error-ErrorLabel">{{ text.error }}&nbsp;{{ errorId }}</h1>
        <div class="error-message">
          <p id="Error-ErrorMessageLabel">{{ errorMessage }}</p>
          <p id="Error-ContactItSupportLabel" class="support">
            {{ text.support }}
          </p>
        </div>
        <button mat-flat-button color="primary" [routerLink]="redirectPath" id="Error-ReturnToPreviousPageButton">
          {{ text.button }}
        </button>
      </div>
      <footer>
        <div class="copyright" id="Error-CopyrightLabel">{{ text.copyright }}</div>
      </footer>
    </div>
  </div>
}
