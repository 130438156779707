<div class="profile-bar">
  <div class="icon-cloud"></div>
  <div class="stratus-logo"></div>
  <div class="profile-container" [matMenuTriggerFor]="menu" (click)="isMenuOpen = true">
    @if (currentUser()) {
      <div class="user-info">
        <div class="welcome" id="ProfileBar-WelcomeLabel">
          Welcome, <span class="login-user-name">{{ currentUser()?.first_name }}</span>
        </div>
        <sub class="user-role" id="ProfileBar-RoleLabel">{{
          currentUser()?.role === adminRole ? 'Werfen Cloud Administrator' : ''
        }}</sub>
      </div>
    }
    <div class="icon-angle-up" [@chevronState]="isMenuOpen ? 'open' : 'closed'" id="ProfileBar-ChevronIcon"></div>
  </div>
</div>

<mat-menu #menu="matMenu" yPosition="below" (closed)="isMenuOpen = false">
  <div mat-menu-item (click)="showAbout()" id="ProfileBar-AboutWerfenCloudButton">About Werfen Cloud</div>
  <div mat-menu-item (click)="logout()" class="separated-menu-item" id="ProfileBar-LogoutButton">Logout</div>
</mat-menu>
