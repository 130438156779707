export enum LOGIN {
  LOCAL = 'LOCAL',
  SSO = 'SSO',
}

export type LoginKey = keyof typeof LOGIN;
export const LOGIN_DISPLAY: Record<LoginKey, string> = {
  LOCAL: 'Local',
  SSO: 'SSO',
};
