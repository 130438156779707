import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ErrorComponent } from './error.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { DsCommonUiModule } from '@digital-factory/ds-common-ui';

@NgModule({
  declarations: [ErrorComponent],
  exports: [ErrorComponent],
  imports: [BrowserModule, MatButtonModule, RouterModule, DsCommonUiModule],
})
export class ErrorModule {}
