import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 * Customize Table/Grid paginator text. Note: this is ngx-translate friendly.
 */
export class CustomPaginator extends MatPaginatorIntl {
  constructor() {
    super();
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0) {
      return '0 items';
    } else if (length === 1) {
      return '1 to 1 of 1 item';
    } else {
      const start = page * pageSize;
      const end = Math.min(start + pageSize, length);

      return `${start + 1} to ${end} of ${length} items`;
    }
  };
}
