import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, inject } from '@angular/core';
import { AuthenticationService, AuthorizationService } from '@digital-factory/ds-common-ui';
import { MatDialog } from '@angular/material/dialog';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { applicationRoleWerfenAdmin } from '../../../info-model/application';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-profile-bar',
  templateUrl: './profile-bar.component.html',
  styleUrls: ['./profile-bar.component.scss'],
  animations: [
    trigger('chevronState', [
      state('closed', style({ transform: 'rotate(-180deg)' })),
      state('open', style({ transform: 'rotate(0)' })),
      transition('open => closed', animate('200ms ease-out')),
      transition('closed => open', animate('200ms ease-out')),
    ]),
  ],
})
export class ProfileBarComponent {
  readonly adminRole = applicationRoleWerfenAdmin;
  isMenuOpen = false;

  private readonly dialog = inject(MatDialog);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly authorizationService = inject(AuthorizationService);

  // TODO any will be removed when we have a platform authenticate wrapper on top of the shared authenticate API
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  currentUser = toSignal<any>(this.authorizationService.currentUser$);

  logout() {
    this.authorizationService.cleanUp();
    this.authenticationService.logout();
  }

  showAbout() {
    this.dialog.open(AboutDialogComponent, {
      height: '500px',
      minWidth: '850px',
    });
  }
}
